import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import {HttpClientModule} from '@angular/common/http';
import { Device } from '@ionic-native/device/ngx';
import { IonicStorageModule } from '@ionic/storage';
import { SQLitePorter } from '@ionic-native/sqlite-porter/ngx';
import { SQLite } from '@ionic-native/sqlite/ngx';
import { ImageModalPagePageModule } from './pages/image-modal-page/image-modal-page.module';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { GlobalconstantService} from './services/globalconstant.service'
import { GoogleMaps } from '@ionic-native/google-maps';
import { Network } from '@ionic-native/network/ngx';


@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [BrowserModule, IonicModule.forRoot(), AppRoutingModule,HttpClientModule,IonicStorageModule.forRoot(),ImageModalPagePageModule],
  providers: [
    StatusBar,
    SplashScreen,
    Device,
    IonicStorageModule,
    SQLite,
    SQLitePorter,
    GlobalconstantService,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    Geolocation,
    GoogleMaps,
    Network
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
