import { Component, OnInit,Input, ElementRef} from '@angular/core';
import { NavParams, ModalController } from '@ionic/angular';

@Component({
  selector: 'app-image-modal-page',
  templateUrl: './image-modal-page.page.html',
  styleUrls: ['./image-modal-page.page.scss'],
})
export class ImageModalPagePage implements OnInit {

  // @ViewChild(ElementRef)slider: ElementRef;
  @Input() slides: ElementRef
  img: any;
 
  sliderOpts = {
    zoom: {
      maxRatio: 5
    }
  };
 
  constructor(private navParams: NavParams, private modalController: ModalController) { }
 
  ngOnInit() {
    
    this.img = this.navParams.get('img');   
  }
 
  zoom(zoomIn: boolean) {
    let zoom = this.slides.nativeElement.swiper.zoom;
    if (zoomIn) {
      zoom.in();
    } else {
      zoom.out();
    }
  }
 
  close() {
    this.modalController.dismiss();
  }
}
