import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { config }     from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NotifcationsService {

  constructor(private http:HttpClient) { }

  notifycount :any;
  getnotifycount(iOrgNo,sPublicToken,iMobileNo){                    //Get The Organization Notification unread message count   
  var Parms =
  {   'iMobileNo' : iMobileNo,
      'iDialingNo': 91,
      'iMessageNo': 3,    
      'iCompanyNo': config.companyNo,
      'iProductNo': config.productNo,
      'iRegionCode':config.regionCode,
      'sPublicToken': sPublicToken,
      'iOrgNo': iOrgNo,    
      'iFunctionalityCode': 1,
      'sUserTypeCd': 'M',
      'lUserId': 0,
      'sPageFromName': 'organizationService - getOrgAnnouncement()'
    };
    return new Promise((resolve, reject)=>{
      let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      // console.log(config.api_URL);
      // console.log(JSON.stringify(Parms));
      this.http.post(config.api_URL+'/Mobile/WAPM1017', JSON.stringify(Parms), {headers: headers}).subscribe(res =>{           
          resolve(res);
      }, (err) =>{
          reject(err);
      });           
    })
  }

  getnotification(iOrgNo,sPublicToken,iMobileNo){                    //Get The Organization Announcement Details Orgdashboard   
  var Parms =
  {   'iMobileNo' : iMobileNo,
      'iDialingNo': 91,
      'iMessageNo': 3,    
      'iCompanyNo': config.companyNo,
      'iProductNo': config.productNo,
      'iRegionCode':config.regionCode,
      'sPublicToken': sPublicToken,
      'iOrgNo': iOrgNo,    
      'iFunctionalityCode': 2,
      'sUserTypeCd': 'M',
      'lUserId': 0,
      'sPageFromName': 'organizationService - getOrgAnnouncement()'
    };
    return new Promise((resolve, reject)=>{
      let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      // console.log(config.api_URL);
      // console.log(JSON.stringify(Parms));
      this.http.post(config.api_URL+'/Mobile/WAPM1017', JSON.stringify(Parms), {headers: headers}).subscribe(res =>{           
          resolve(res);
      }, (err) =>{
          reject(err);
      });           
    })
  }

  readnotify(iOrgNo,sPublicToken,iMobileNo,iMessageNo){                    //Get The Organization Announcement Details Orgdashboard   
    var Parms =
    {   'iMobileNo' : iMobileNo,
        'iDialingNo': 91,
        'iMessageNo': iMessageNo,    
        'iCompanyNo': config.companyNo,
        'iProductNo': config.productNo,
        'iRegionCode':config.regionCode,
        'sPublicToken': sPublicToken,
        'iOrgNo': iOrgNo,    
        'iFunctionalityCode': 3,
        'sUserTypeCd': 'M',
        'lUserId': 0,
        'sPageFromName': 'organizationService - getOrgAnnouncement()'
      };
      return new Promise((resolve, reject)=>{
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
       // console.log(config.api_URL);
      //  console.log(JSON.stringify(Parms));
        this.http.post(config.api_URL+'/Mobile/WAPM1017', JSON.stringify(Parms), {headers: headers}).subscribe(res =>{           
            resolve(res);
        }, (err) =>{
            reject(err);
        });           
      })
    }

    clicknotify(iOrgNo,sPublicToken,iMobileNo,iMessageNo){       //Get The Organization Announcement Details Orgdashboard   
                
      var Parms =
      {   'iMobileNo' : iMobileNo,
          'iDialingNo': 91,
          'iMessageNo': iMessageNo,    
          'iCompanyNo': config.companyNo,
          'iProductNo': config.productNo,
          'iRegionCode':config.regionCode,
          'sPublicToken': sPublicToken,
          'iOrgNo': iOrgNo,    
          'iFunctionalityCode': 4,
          'sUserTypeCd': 'M',
          'lUserId': 0,
          'sPageFromName': 'notification - ClickNotification()'
        };
        return new Promise((resolve, reject)=>{
          let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
         // console.log(config.api_URL);
         // console.log(JSON.stringify(Parms));
          this.http.post(config.api_URL+'/Mobile/WAPM1017', JSON.stringify(Parms), {headers: headers}).subscribe(res =>{           
              resolve(res);
          }, (err) =>{
              reject(err);
          });           
        })
      }
}
