import { Injectable }              from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { config }                  from 'src/environments/environment';
import { LocationStrategy }        from '@angular/common';
import { BehaviorSubject } from 'rxjs';
import { SQLite, SQLiteObject } from '@ionic-native/sqlite/ngx';
import { Device } from '@ionic-native/device/ngx';
import { Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { GlobalconstantService } from './globalconstant.service';


@Injectable({
  providedIn: 'root'
})
export class AccountserviceService {

  authenticate = new BehaviorSubject(false);
  data: any;
  constructor(private global:GlobalconstantService,private storage: Storage, private http:HttpClient,private location: LocationStrategy,  private sqlite: SQLite,private device:Device,private plt:Platform) 
  {   
    this.plt.ready().then(()=>{  
        this.sqlite.create({
              name: 'OCMOB.db',
              location: 'default'
            })
            .then((db: SQLiteObject) => {        
              db.executeSql('SELECT * FROM Accounts', [])
                .then((data) => {
               // console.log(data);
              //  console.log(data.rows.length);
                //  alert(data.rows.length);
                var len = data.rows.length;          
                // alert(len);
                if(len >0)
                {
                  for (var i = 0; i < len; i++) { 
                    window.sessionStorage.setItem("PublicToken", data.rows.item(i).Token);
                    window.sessionStorage.setItem("InternalLogNo", data.rows.item(i).LogNo);
                    window.sessionStorage.setItem("sCurrDepartmentNo", '0');
                    window.sessionStorage.setItem("LogInOutProcess", data.rows.item(i).Process); // 1- Logout User 0 - Login User
                    window.sessionStorage.setItem("ID", data.rows.item(i).id);
                
                    var publicTokenNo   = window.sessionStorage.getItem("PublicToken");   //  Set Session Token No to $scope variable
                    var KeyName         = window.sessionStorage.getItem("LogInOutProcess");
                    var InternalLogNo   = window.sessionStorage.getItem("InternalLogNo");     
                    var uuid            = "Mobile";
                    var manufacturer    = this.device.version;
                    var model           = this.device.platform;

                    // alert(publicTokenNo)
                  this.AccountLoginExistingUser(publicTokenNo,'android',uuid,manufacturer,model,KeyName,InternalLogNo)
                  
                }}
              })
            })
      });

      
      // checkToken() {
        
      //   var publicTokenNo   = window.sessionStorage.getItem("PublicToken"); 
      //   if(publicTokenNo !='')
      //   {       
      //     this.authenticate.next(true);
      //   }  
      }    

  AccountRegister(sUserFirstName,sUserSecondName,sEmailID,sPassword,iGenderCode,dDOB,lMobileNo)
  {
    var Parms = {
      'iCompanyNo': config.companyNo,
      'iProductNo': config.productNo,
      'iRegionCode':config.regionCode,
      'sEmailID':sEmailID,
      'sPassword':sPassword,
      'iDialingNo':91,
      'lMobileNo':lMobileNo,
      'sUserFirstName':sUserFirstName,
      'sUserSecondName':sUserSecondName,
      'iGenderCode':iGenderCode,
       'dDOB': dDOB,
      // 'dDOB': dDOB,
      'sPublicToken': '',
      'iOrgNo': 0,
      'iFunctionalityCode': 2,
      'sUserTypeCd': 'M',
      'lUserId': 0,
      'sPageFromName': 'AccountService - AccountRegister()'
  };
    return new Promise((resolve, reject)=>{
      let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
     // console.log(config.api_URL);
    //  console.log(JSON.stringify(Parms));
      this.http.post(config.api_URL+'/Mobile/WAPM1010', JSON.stringify(Parms), {headers: headers}).subscribe(res =>{
        //  alert("Logic");
          resolve(res);
      }, (err) =>{
          reject(err);
      }
    );           
    })
  }
  AccountForgotPassword(lUserId,sEmailID)
  {
    var Parms = {
      'iCompanyNo': config.companyNo,
      'iProductNo': config.productNo,
      'iRegionCode':config.regionCode,
      'sEmailID':sEmailID,
      'sPassword':'',
      'iDialingNo':'',
      'lMobileNo':'',
      'sUserFirstName':'',
      'sUserSecondName':'',
      'iGenderCode':'',
      // 'dDOB': new Date (dDOB),
      'dDOB': '',
      'sPublicToken': '',
      'iOrgNo': 0,
      'iFunctionalityCode': 3,
      'sUserTypeCd': 'M',
      'lUserId': lUserId,
      'sPageFromName': 'AccountService - AccountForgotPassword()'
    };
    return new Promise((resolve, reject)=>{
      let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
     // console.log(config.api_URL);
    //  console.log(JSON.stringify(Parms));
      this.http.post(config.api_URL+'/Mobile/WAPM1010', JSON.stringify(Parms), {headers: headers}).subscribe(res =>{
        //  alert("Logic");
          resolve(res);
      }, (err) =>{
          reject(err);
      }
    );           
    })
  }

  AccountCheckOTP(lUserId,sEmailID,iOTP)
  {
    var Parms = {
      'iCompanyNo': config.companyNo,
      'iProductNo': config.productNo,
      'iRegionCode':config.regionCode,
      'sEmailID':sEmailID,
      'sPassword':'',
      'iDialingNo':'',
      'lMobileNo':'',
      'sUserFirstName':'',
      'iOTP':iOTP,
      'sUserSecondName':'',
      'iGenderCode':'',
      // 'dDOB': new Date (dDOB),
      'dDOB': '',
      'sPublicToken': '',
      'iOrgNo': 0,
      'iFunctionalityCode': 4,
      'sUserTypeCd': 'M',
      'lUserId': lUserId,
      'sPageFromName': 'AccountService - AccountCheckOTP()'
  };
  return new Promise((resolve, reject)=>{
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    //console.log(config.api_URL);
    //console.log(JSON.stringify(Parms));
    this.http.post(config.api_URL+'/Mobile/WAPM1010', JSON.stringify(Parms), {headers: headers}).subscribe(res =>{
      //  alert("Logic");
        resolve(res);
    }, (err) =>{
        reject(err);
    }
  );           
  })
  }

  isAuthenticated()
  {
    // alert(this.authenticate.value)
    return this.authenticate.value;
  }

  AccountLoginExistingUser(publicTokenNo,currentPlatform,uuid,manufacturer,model,KeyName,InternalLogNo)
  {
    //  alert('already login')
    var Parms = {
      'iCompanyNo': config.companyNo,
      'iProductNo': config.productNo,
      'iRegionCode':config.regionCode,
      'sEmailID':'',
      'sPassword':'',
      'sLoginURL':(<any>this.location)._platformLocation.location.href,
      'sDeviceManufacture':manufacturer,
      'sDeviceUUID':uuid,
      'sDevicePlatform':currentPlatform,
      'sDeviceModel':model,
      'sPublicToken': publicTokenNo,
      'iOrgNo': 0,
      'sKeyName':KeyName,
      'InternalLogNo':InternalLogNo,
      'iFunctionalityCode': 15,
      'sUserTypeCd': 'M',
      'lUserId': 0,
      'sPageFromName': 'AccountService - AccountLoginExistingUser()'
    };
    return new Promise((resolve, reject)=>{
      let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    //  console.log(config.api_URL);
    //  console.log(JSON.stringify(Parms));
      this.http.post(config.api_URL+'/Mobile/WAPM1010', JSON.stringify(Parms), {headers: headers}).subscribe(res =>{
        //  alert("Logic");      
          resolve(res); 
          this.data = res    
          // alert(this.data.iUserStatus)
          if (this.data.iUserStatus == '1260') {    
            this.global.sideUsername          = this.data.sUserName;
            this.global.sideUserPhoto         = this.data.sUserPhoto;              
            this.global.sidemobile            = this.data.lMobileNo;
            this.global.sideemail             = this.data.sEmailID;   
            this.authenticate.next(true);  
          }
          
           
      }, (err) =>{  
          reject(err);
      }
    );           
    })
  }

  Login(vUserId,vPassword,vEmailID,currentPlatform,uuid,manufacturer,model)
  {  
    var string = this.device.platform;
   // console.log('Device UUID is: ' + string);
    var Parms = {
              'iCompanyNo'        : config.companyNo,
              'iProductNo'        : config.productNo,
              'iRegionCode'       : config.regionCode,
              'sEmailID'          : vEmailID,
              'sPassword'         : vPassword,
              'sLoginURL'         : (<any>this.location)._platformLocation.location.href,
              'sDeviceManufacture': manufacturer,
              'sDeviceUUID'       : uuid,
              'sDevicePlatform'   : currentPlatform,
              'sDeviceModel'      : model,
              'sPublicToken'      : '',
              'iOrgNo'            : 0,
              'iFunctionalityCode': 1,
              'sUserTypeCd'       : 'M',
              'lUserId'           : vUserId,
              'sPageFromName'     : 'AccountService - AccountLogin()'
            };         
       // console.log('Device UUID is: ' + this.device.model);
        return new Promise((resolve, reject)=>{
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
       // console.log(config.api_URL);
       // console.log(JSON.stringify(Parms));
        this.http.post(config.api_URL+'/Mobile/WAPM1010', JSON.stringify(Parms), {headers: headers}).subscribe(res =>{        
            resolve(res);
           // console.log(res)
            this.data = res;
            if (this.data.iUserStatus == '1260') {            
              this.authenticate.next(true);  
            }
        }, (err) =>{
            reject(err);
        }
      );           
    }
  )
  }

  AccountLogout(publicTokenNo,InternalLogNo)
  {
    // alert("logout")
    var Parms = {
      'iCompanyNo': config.companyNo,
      'iProductNo': config.productNo,
      'iRegionCode':config.regionCode,
      'sPublicToken': publicTokenNo,
      'iFunctionalityCode': 13,
      'InternalLogNo':InternalLogNo,
      'sUserTypeCd': 'M',
      'sPageFromName': 'AccountService - AccountLogout()'
    };
    return new Promise((resolve, reject)=>{
      let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    //  console.log(config.api_URL);
    //  console.log(JSON.stringify(Parms));
      this.http.post(config.api_URL+'/Mobile/WAPM1010', JSON.stringify(Parms), {headers: headers}).subscribe(res =>{
        //  alert("Logic");
          resolve(res);
        //  console.log(res)
          if (res) {    
            // alert('tue')       
            this.authenticate.next(false);  
          }
      }, (err) =>{
          reject(err);
      }
    );           
    })  
  }
  AccountChangePwd(lUserId,sEmailID,iOTP,sPassword)
  {
    var Parms = {
      'iCompanyNo': config.companyNo,
      'iProductNo': config.productNo,
      'iRegionCode':config.regionCode,
      'sEmailID':sEmailID,
      'sPassword':sPassword,
      'iDialingNo':'',
      'lMobileNo':'',
      'sUserFirstName':'',
      'iOTP':iOTP,
      'sUserSecondName':'',
      'iGenderCode':'',
      // 'dDOB': new Date (dDOB),
      'dDOB': '',
      'sPublicToken': '',
      'iOrgNo': 0,
      'iFunctionalityCode': 5,
      'sUserTypeCd': 'M',
      'lUserId': lUserId,
      'sPageFromName': 'AccountService - AccountChangePwd()'
  };
  return new Promise((resolve, reject)=>{
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  //  console.log(config.api_URL);
  //  console.log(JSON.stringify(Parms));
    this.http.post(config.api_URL+'/Mobile/WAPM1010', JSON.stringify(Parms), {headers: headers}).subscribe(res =>{
      //  alert("Logic");
        resolve(res);
    }, (err) =>{
        reject(err);
    }
  );           
  })
}
}
