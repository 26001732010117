import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthenticationService } from './services/authentication.service';

const routes: Routes = [

  // { path: '', redirectTo: 'downloadapp', pathMatch: 'full' },
  { path: 'downloadapp', loadChildren: './pages/downloadapp/downloadapp.module#DownloadappPageModule' },
  { path: 'home', loadChildren: './pages/home/home.module#HomePageModule' },
  { path: 'login', loadChildren: './pages/login/login.module#LoginPageModule' },
  { path: 'nointernet', loadChildren: './pages/nointernet/nointernet.module#NointernetPageModule' },
  { path: 'default-area', loadChildren: './pages/default-area/default-area.module#DefaultAreaPageModule' },
  { path: 'home1',canActivate:[AuthenticationService] ,loadChildren: './pages/home1/home1.module#Home1PageModule' },
  { path: 'employeedashboard', loadChildren: './pages/employeedashboard/employeedashboard.module#EmployeedashboardPageModule' },
  { path: 'employeeorgdashboard', loadChildren: './pages/employeeorgdashboard/employeeorgdashboard.module#EmployeeorgdashboardPageModule' },
  // { path: 'aboutus', loadChildren: './pages/aboutus/aboutus.module#AboutusPageModule' },
  { path: 'org-profile', loadChildren: './pages/org-profile/org-profile.module#OrgProfilePageModule' },
  { path: 'location-map', loadChildren: './pages/location-map/location-map.module#LocationMapPageModule' },
  { path: 'album-menu', loadChildren: './pages/album-menu/album-menu.module#AlbumMenuPageModule' },
  { path: 'leader-message', loadChildren: './pages/leader-message/leader-message.module#LeaderMessagePageModule' },
  { path: 'keycontacts', loadChildren: './pages/keycontacts/keycontacts.module#KeycontactsPageModule' },
  { path: 'facilities', loadChildren: './pages/facilities/facilities.module#FacilitiesPageModule' },
  { path: 'org-announcements', loadChildren: './pages/org-announcements/org-announcements.module#OrgAnnouncementsPageModule' },
  { path: 'news', loadChildren: './pages/news/news.module#NewsPageModule' },
  { path: 'calendar', loadChildren: './pages/calendar/calendar.module#CalendarPageModule' },
  { path: 'mom', loadChildren: './pages/mom/mom.module#MOMPageModule' },
  { path: 'feedback', loadChildren: './pages/feedback/feedback.module#FeedbackPageModule' },
  { path: 'department-list', loadChildren: './pages/department-list/department-list.module#DepartmentListPageModule' },
  { path: 'dept-dashboard', loadChildren: './pages/dept-dashboard/dept-dashboard.module#DeptDashboardPageModule' },
  { path: 'deptannouncement', loadChildren: './pages/deptannouncement/deptannouncement.module#DeptannouncementPageModule' },
  { path: 'departmentcalendar', loadChildren: './pages/departmentcalendar/departmentcalendar.module#DepartmentcalendarPageModule' },
  { path: 'classsection', loadChildren: './pages/classsection/classsection.module#ClasssectionPageModule' },
  { path: 'daysbyclass', loadChildren: './pages/daysbyclass/daysbyclass.module#DaysbyclassPageModule' },
  { path: 'classtimetablebyclass', loadChildren: './pages/classtimetablebyclass/classtimetablebyclass.module#ClasstimetablebyclassPageModule' },
  { path: 'classsectionlistsubject', loadChildren: './pages/classsectionlistsubject/classsectionlistsubject.module#ClasssectionlistsubjectPageModule' },
  { path: 'daysbysubject', loadChildren: './pages/daysbysubject/daysbysubject.module#DaysbysubjectPageModule' },
  { path: 'classtimetablebysubject', loadChildren: './pages/classtimetablebysubject/classtimetablebysubject.module#ClasstimetablebysubjectPageModule' },
  { path: 'classlistforstudent', loadChildren: './pages/classlistforstudent/classlistforstudent.module#ClasslistforstudentPageModule' },
  { path: 'studentlist', loadChildren: './pages/studentlist/studentlist.module#StudentlistPageModule' },
  { path: 'childdashboard', loadChildren: './pages/childdashboard/childdashboard.module#ChilddashboardPageModule' },
  { path: 'myarea', loadChildren: './pages/myarea/myarea.module#MyareaPageModule' },
  { path: 'leavequota', loadChildren: './pages/leavequota/leavequota.module#LeavequotaPageModule' },
  { path: 'leavehistory', loadChildren: './pages/leavehistory/leavehistory.module#LeavehistoryPageModule' },
  { path: 'route', loadChildren: './pages/route/route.module#RoutePageModule' },
  { path: 'tracking', loadChildren: './pages/tracking/tracking.module#TrackingPageModule' },
  { path: 'viewannouncement', loadChildren: './pages/viewannouncement/viewannouncement.module#ViewannouncementPageModule' },
  { path: 'viewannouncementdetails', loadChildren: './pages/viewannouncementdetails/viewannouncementdetails.module#ViewannouncementdetailsPageModule' },
  { path: 'announcementpost', loadChildren: './pages/announcementpost/announcementpost.module#AnnouncementpostPageModule' },
  { path: 'spotonawards', loadChildren: './pages/spotonawards/spotonawards.module#SpotonawardsPageModule' },
  { path: 'myawardhistory', loadChildren: './pages/myawardhistory/myawardhistory.module#MyawardhistoryPageModule' },
  { path: 'giveawards', loadChildren: './pages/giveawards/giveawards.module#GiveawardsPageModule' },
  { path: 'giveawarddetails', loadChildren: './pages/giveawarddetails/giveawarddetails.module#GiveawarddetailsPageModule' },
  { path: 'employeepicker', loadChildren: './pages/employeepicker/employeepicker.module#EmployeepickerPageModule' },
  { path: 'postaward', loadChildren: './pages/postaward/postaward.module#PostawardPageModule' },
  { path: 'orgdashsearchorg', loadChildren: './pages/orgdashsearchorg/orgdashsearchorg.module#OrgdashsearchorgPageModule' },
  { path: 'studdept', loadChildren: './pages/studdept/studdept.module#StuddeptPageModule' },
  { path: 'messages', loadChildren: './pages/messages/messages.module#MessagesPageModule' },
  { path: 'academics', loadChildren: './pages/academics/academics.module#AcademicsPageModule' },
  { path: 'billssummary', loadChildren: './pages/billssummary/billssummary.module#BillssummaryPageModule' },
  { path: 'recentpayments', loadChildren: './pages/recentpayments/recentpayments.module#RecentpaymentsPageModule' },
  { path: 'recentbills', loadChildren: './pages/recentbills/recentbills.module#RecentbillsPageModule' },
  { path: 'mycontactdetails', loadChildren: './pages/mycontactdetails/mycontactdetails.module#MycontactdetailsPageModule' },
  { path: 'studenntattendance', loadChildren: './pages/studenntattendance/studenntattendance.module#StudenntattendancePageModule' },
  { path: 'aluminimenu', loadChildren: './pages/aluminimenu/aluminimenu.module#AluminimenuPageModule' },
  { path: 'sponsormenu', loadChildren: './pages/sponsormenu/sponsormenu.module#SponsormenuPageModule' },
  { path: 'register', loadChildren: './pages/register/register.module#RegisterPageModule' },
  { path: 'changepwd', loadChildren: './pages/changepwd/changepwd.module#ChangepwdPageModule' },
  { path: 'forgotpassword', loadChildren: './pages/forgotpassword/forgotpassword.module#ForgotpasswordPageModule' },
  { path: 'checkotp', loadChildren: './pages/checkotp/checkotp.module#CheckotpPageModule' },
  { path: '', loadChildren: './pages/main/main.module#MainPageModule' },
  { path: 'image-modal-page', loadChildren: './pages/image-modal-page/image-modal-page.module#ImageModalPagePageModule' },
  { path: 'photoalbum', loadChildren: './pages/photoalbum/photoalbum.module#PhotoalbumPageModule' },
  { path: 'applyleave', loadChildren: './pages/applyleave/applyleave.module#ApplyleavePageModule' },
  { path: 'sponsorrecentpayments', loadChildren: './pages/sponsorrecentpayments/sponsorrecentpayments.module#SponsorrecentpaymentsPageModule' },
  { path: 'sendemail', loadChildren: './pages/sendemail/sendemail.module#SendemailPageModule' },
  { path: 'sendsms', loadChildren: './pages/sendsms/sendsms.module#SendsmsPageModule' },
  { path: 'sendmessagehistory', loadChildren: './pages/sendmessagehistory/sendmessagehistory.module#SendmessagehistoryPageModule' },
  // { path: 'pages', loadChildren: './pages/pages.module#PagesPageModule' },
  { path: 'myawardhistorydetails', loadChildren: './pages/myawardhistorydetails/myawardhistorydetails.module#MyawardhistorydetailsPageModule' },
  { path: 'privacypolicy', loadChildren: './pages/privacypolicy/privacypolicy.module#PrivacypolicyPageModule' },
  { path: 'terms', loadChildren: './pages/terms/terms.module#TermsPageModule' },
  { path: 'support', loadChildren: './pages/support/support.module#SupportPageModule' },
  { path: 'academicsscore', loadChildren: './pages/academicsscore/academicsscore.module#AcademicsscorePageModule' },
  { path: 'searchorg', loadChildren: './pages/searchorg/searchorg.module#SearchorgPageModule' },
  { path: 'billdetails', loadChildren: './pages/billdetails/billdetails.module#BilldetailsPageModule' },
  { path: 'sidemenu', loadChildren: './pages/sidemenu/sidemenu.module#SidemenuPageModule' },
  { path: 'tab3', loadChildren: './pages/tab3/tab3.module#Tab3PageModule' },
  { path: 'notificationdetails', loadChildren: './pages/notificationdetails/notificationdetails.module#NotificationdetailsPageModule' },
  { path: 'sendnotification', loadChildren: './pages/sendnotification/sendnotification.module#SendnotificationPageModule' },
  { path: 'videoalbum', loadChildren: './pages/videoalbum/videoalbum.module#VideoalbumPageModule' },
  // { path: 'tab1', loadChildren: './pages/tab1/tab1.module#Tab1PageModule' },
  // { path: 'tab2', loadChildren: './pages/tab2/tab2.module#Tab2PageModule' },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
