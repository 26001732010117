// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false
};

export const config= {
  production: false,
    appName: 'OurCampus360',
    appVersion: '2.1.0.0',
    companyNo: 10001,
    productNo: 10017,
    // QA - Starts
    regionCode: 200102,
    api_URL:'https://qa.services360.webapi.hititsoft.com'
    // QA - Ends
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';
// Included with Angular CLI;
