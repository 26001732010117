import { Component } from '@angular/core';
import { Platform, MenuController, LoadingController, AlertController, IonRouterOutlet } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Device } from '@ionic-native/device/ngx';
import { Router } from '@angular/router';
import { AccountserviceService } from './services/accountservice.service';
import { SQLite, SQLiteObject } from '@ionic-native/sqlite/ngx';
import { GlobalconstantService } from './services/globalconstant.service';
import { Storage } from '@ionic/storage';
import { Network } from '@ionic-native/network/ngx';
import { CommonserviceService } from './services/commonservice.service';
import { config } from 'src/environments/environment';
import { NotifcationsService } from './services/notifcations.service';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent {
  
  public database: SQLite;
  public people: Array<Object>
  data:any;
  image:any;        
  sUserName  :any;  
  lMobileNo  :any;   
  sEmailID :any;
  private loading;
  isApp: boolean;
  state:boolean;
  mobilelenght:any;
  backButtonSubscription: any;
  routerOutlet: any;
  appDetails:any;
  mobileno:any;
  allunreadnotification:any;
  notify:any; notifycount :  any;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private router:Router,
    private AccountService:AccountserviceService,
    private sqlite: SQLite,   
    private loadingCtrl:LoadingController,
    private global:GlobalconstantService,
    private device:Device,
    private network:Network,
    private alertcontroller:AlertController,
    private  menu: MenuController,
    private CommonService:CommonserviceService,
    private notification: NotifcationsService
  ) {
    this.initializeApp();   
    this.backButtonEvent(); 
  }
  backButtonEvent() {
    this.platform.backButton.subscribe(() => {
      if (!this.menu.isOpen () ) {
          this.menu.close ();
      }  else {
        this.menu.close ();
     }
  });
  }
  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.backgroundColorByHexString('#000000');
      if ("none" === this.network.type) 
      {
        this.presentAlert()
      }
      else if(true)                         // Update Code
      {
        this.splashScreen.hide();
        this.isApp = (!this.platform.is('mobileweb'));       
        if(this.isApp === true) 
        {     
          if (this.platform.is('android') || (this.platform.is('ios')))
          {
          this.CommonService.GetParameterValue('SETTING_HELP_CONTACT_DETAILS')
          .then((result) =>{
            this.appDetails            = result;   
            console.log(this.appDetails)
            if(this.appDetails.VersionNo > config.appVersion)
            {
              this.Update()
            }
            })  
          }
        }     
      }                     
    });

    this.AccountService.authenticate.subscribe(state=>{   
   // console.log('state value' + state)
    this.isApp = (!this.platform.is('mobileweb'));       
    if(this.isApp === true) 
    {     
      if (this.platform.is('android') || (this.platform.is('ios')))
      {
        if(state)
        {
          this.router.navigate(['/main/home1']);  
        }
        else
        {
          this.router.navigate(['/home']);  
        }
      }
    }
    })   
  }
  async presentAlert() {   
  const alert = await this.alertcontroller.create({
    header: 'Information',     
    message: 'No Internet Connection Available. Please try again later.',
    buttons:  [{
      text: 'OK',
      handler: () => {          
        navigator['app'].exitApp();
      }
    }],backdropDismiss: false
  });
  await alert.present()  
  } 
  async Update() {   
    this.isApp = (!this.platform.is('mobileweb'));
    if(this.isApp === true) 
    {
      if (this.platform.is('android') )
      { 
        const alert = await this.alertcontroller.create({
          header: 'New Version Available',     
          message: 'There is a newer version available for download!Please update the app by visiting the Store.',
          buttons:  [{
            text: 'OK',          
            handler: () => {          
              window.open("https://play.google.com/store/apps/details?id=com.OurCampus360.app&hl=en", "_system");
            }
          }],backdropDismiss: false
        });
        await alert.present()
      }
      else
      {
        const alert = await this.alertcontroller.create({
          header: 'New Version Available',     
          message: 'There is a newer version available for download!Please update the app by visiting the Store.',
          buttons:  [{
            text: 'OK',          
            handler: () => {          
              window.open("https://itunes.apple.com/lb/app/com.OurCampus360.app/id1437752298?mt=8");
            }
          }],backdropDismiss: false
        });
        await alert.present()
      }
    }
  }
  ngOnInit() 
  {    
    this.image         = this.global.sideUserPhoto;
    this.sUserName     = this.global.sideUsername;
    this.lMobileNo     = this.global.sidemobile;
    this.sEmailID      = this.global.sideemail   ;
  }

  GoHome()
  {      
    this.router.navigate(['/main/home1'])
  }
  Settings()
  {    
    this.router.navigate(['/main/tab2'])
  }
  Notification()
  {    
    this.notifycount = 0
  // 
    let orgno         = window.sessionStorage.getItem("sOrgNo");;
    let publictoken   = window.sessionStorage.getItem("PublicToken"); 
    this.mobileno     = window.localStorage.getItem("lMobileNo")

    this.notification.getnotification(orgno,publictoken,this.mobileno)   
    .then((result) =>{  
        this.allunreadnotification = result
        console.log(this.allunreadnotification)
        for(var i=0;i<this.allunreadnotification.lstNotification.length;i++)
        {
          console.log(this.allunreadnotification.lstNotification[i].AnnouncmentNo)
          if(this.allunreadnotification.lstNotification[i].MessageRead == false)
          {
            console.log(this.allunreadnotification.lstNotification[i].MessageRead)
            console.log(this.allunreadnotification.lstNotification[i].MessageNo)
            let orgno         = window.sessionStorage.getItem("sOrgNo")
            let publictoken   = window.sessionStorage.getItem("PublicToken"); 
            this.mobileno     = window.localStorage.getItem("lMobileNo");
            let MessageNo    = this.allunreadnotification.lstNotification[i].MessageNo;
            this.notification.readnotify(orgno,publictoken,this.mobileno,MessageNo)   
          }
        }
        this.router.navigate(['/main/tab3'])
    })
    
  }
  Logout()
   {        
     this.isApp = (!this.platform.is('mobileweb'));
     if(this.isApp === true) 
     {
       if (this.platform.is('android') || (this.platform.is('ios')))
       {           
         this.sqlite.create({
           name: 'OCMOB.db',
           location: 'default'
         })
         .then((db: SQLiteObject) => {        
           db.executeSql('SELECT * FROM Accounts', [])
             .then((data) => {
             console.log(data);
             console.log(data.rows.length);
             var len = data.rows.length;            
             for (var i = 0; i < len; i++) { 
               window.sessionStorage.setItem("PublicToken", data.rows.item(i).Token); // set Public Token No to Session Storage
               window.sessionStorage.setItem("TestTokenNo", data.rows.item(i).Token);
               window.sessionStorage.setItem("InternalLogNo", data.rows.item(i).LogNo);
               let publicTokenNo = window.sessionStorage.getItem("PublicToken");   //  Set Session Token No to $scope variable
               let InternalLogNo = window.sessionStorage.getItem("InternalLogNo");
             
               this.AccountService.AccountLogout(publicTokenNo, InternalLogNo)
               .then((result)=>{
                 this.data = result;          
                 if(this.data.iDBReturnCode == "0")
                 {
                   window.localStorage.clear();
                   window.sessionStorage.clear();
                   this.sqlite.create({
                     name: 'OCMOB.db',
                     location: 'default'
                   })
                   .then((db: SQLiteObject) => {        
                     db.executeSql('DELETE FROM Accounts')                                                 
                   })
                   .catch(e => console.log(e));  
                   navigator['app'].exitApp();
                 }
               })
             }
           })
         })  
       }
       else
       {                
         let publicTokenNo = window.sessionStorage.getItem("PublicToken");   //  Set Session Token No to $scope variable
         let InternalLogNo = window.sessionStorage.getItem("InternalLogNo");
         this.AccountService.AccountLogout(publicTokenNo,InternalLogNo)
         .then((result)=>{
           this.data = result;
          
           if(this.data.iDBReturnCode == "0")
           {
             window.sessionStorage.setItem("PublicToken", "0");
             window.sessionStorage.setItem("TestTokenNo", "0");
             window.sessionStorage.setItem("InternalLogNo", "0");
             window.localStorage.clear();
             window.sessionStorage.clear();                         
             this.router.navigate(['default-area'])
           }
         })
       }
     }
     else
     {   
       let publicTokenNo = window.sessionStorage.getItem("PublicToken");   //  Set Session Token No to $scope variable
       let InternalLogNo = window.sessionStorage.getItem("InternalLogNo");
       this.AccountService.AccountLogout(publicTokenNo,InternalLogNo)
       .then((result)=>{
         this.data = result;         
         if(this.data.iDBReturnCode == "0")
         {
           window.sessionStorage.setItem("PublicToken", "0");
           window.sessionStorage.setItem("TestTokenNo", "0");
           window.sessionStorage.setItem("InternalLogNo", "0");
           window.localStorage.clear();
           window.sessionStorage.clear();                                          
           this.router.navigate(['default-area'])
          }
        })
      }
    }
}
