import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { AccountserviceService } from './accountservice.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService implements CanActivate{

  constructor(private account:AccountserviceService) { }

  canActivate():boolean{
    return this.account.isAuthenticated()
  }
}
