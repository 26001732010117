import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { config }     from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class CommonserviceService {
  MobileNo:any;
  Email:any;
  constructor(private http:HttpClient) { }

  GetParameterValue(KeyName)
  {
    var Parms = {
      'iCompanyNo': config.companyNo,
      'iProductNo': config.productNo,
      'iRegionCode':config.regionCode,
      'sEmailID':'',
      'sPassword':'',
      'sLoginURL':'',
      'sKeyName':KeyName,
      'sDeviceManufacture':'',
      'sDeviceUUID':'',
      'sDevicePlatform':'',
      'sDeviceModel':'',
      'sPublicToken': '',
      'iOrgNo': 0,
      'iFunctionalityCode': 12,
      'sUserTypeCd': 'M',
      'lUserId': 0,
      'sPageFromName': 'CommonService - GetParameterValue()'
    };return new Promise((resolve, reject)=>{
      let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      // console.log(config.api_URL);
      // console.log(JSON.stringify(Parms));
      this.http.post(config.api_URL+'/Mobile/WAPM1010', JSON.stringify(Parms), {headers: headers}).subscribe(res =>{
        //  alert("Logic");
          resolve(res);
      }, (err) =>{
          reject(err);
      }
    );           
  })
  }
  GetDate()
  {
    var Parms = {
      'iCompanyNo': config.companyNo,
      'iProductNo': config.productNo,
      'iRegionCode':config.regionCode,
      'sEmailID':'',
      'sPassword':'',
      'sLoginURL':'',
      'sDeviceManufacture':'',
      'sDeviceUUID':'',
      'sDevicePlatform':'',
      'sDeviceModel':'',
      'sPublicToken': '',
      'iOrgNo': 0,
      'iFunctionalityCode': 14,
      'sUserTypeCd': 'M',
      'lUserId': 0,
      'sPageFromName': 'CommonService - GetDate()'
    };return new Promise((resolve, reject)=>{
      let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      // console.log(config.api_URL);
      // console.log(JSON.stringify(Parms));
      this.http.post(config.api_URL+'/Mobile/WAPM1010', JSON.stringify(Parms), {headers: headers}).subscribe(res =>{
        //  alert("Logic");
          resolve(res);
      }, (err) =>{
          reject(err);
      }
    );           
    })
  }
}
