import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GlobalconstantService {

   OrgCategory: any;
    MyTitle: any;
    UserDetails:any;
    OTP:any;
    UserId:any;
    UserEmailID:any;
    sideUsername:any;
    sideUserPhoto:any;
    sidemobile:any;
    sideemail:any;
    OrgLog:any;
  constructor() { 
   }

 
}
